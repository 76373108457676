export class ViolationsNginxConst {
  static getAllViolations: () => {
    name: string;
    title: string;
    alarm: boolean;
    block: boolean;
    disabled: boolean;
  }[] = () => {
    return [
      {
        name: "VIOL_ASM_COOKIE_MODIFIED",
        title: "Modified ASM cookie",
        alarm: true,
        block: true,
        disabled: false,
      },
      {
        name: "VIOL_ATTACK_SIGNATURE",
        title: "Attack signature detected",
        alarm: false,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_BLACKLISTED_IP",
        title: "IP is in the deny list",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_BOT_CLIENT",
        title: "Bot Client Detected",
        alarm: true,
        block: true,
        disabled: false,
      },
      {
        name: "VIOL_COOKIE_EXPIRED",
        title: "Expired timestamp",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_COOKIE_LENGTH",
        title: "Illegal cookie length",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_COOKIE_MALFORMED",
        title: "Cookie not RFC-compliant",
        alarm: true,
        block: true,
        disabled: false,
      },
      {
        name: "VIOL_COOKIE_MODIFIED",
        title: "Modified domain cookie(s)",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_DATA_GUARD",
        title: "Data Guard: Information leakage detected",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_ENCODING",
        title: "Failed to convert character",
        alarm: true,
        block: true,
        disabled: false,
      },
      {
        name: "VIOL_EVASION",
        title: "Evasion technique detected",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_FILETYPE",
        title: "Illegal file type",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_FILE_UPLOAD",
        title: "Disallowed file upload content detected",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_FILE_UPLOAD_IN_BODY",
        title: "Disallowed file upload content detected in body",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_GRPC_FORMAT",
        title: "gRPC data does not comply with format settings",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_GRPC_MALFORMED",
        title: "Malformed gRPC data",
        alarm: true,
        block: true,
        disabled: false,
      },
      {
        name: "VIOL_GRPC_METHOD",
        title: "Illegal gRPC method",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_HEADER_LENGTH",
        title: "Illegal header length",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_HEADER_METACHAR",
        title: "Illegal meta character in header",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_HTTP_PROTOCOL",
        title: "HTTP protocol compliance failed",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_HTTP_RESPONSE_STATUS",
        title: "Illegal HTTP response status",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_JSON_FORMAT",
        title: "JSON data does not comply with format settings",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_JSON_MALFORMED",
        title: "Malformed JSON data",
        alarm: true,
        block: true,
        disabled: false,
      },
      {
        name: "VIOL_JSON_SCHEMA",
        title: "JSON data does not comply with JSON schema",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_MANDATORY_PARAMETER",
        title: "Mandatory parameter is missing",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_MANDATORY_REQUEST_BODY",
        title: "Mandatory request body is missing",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_METHOD",
        title: "Illegal method	Alarm",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_PARAMETER",
        title: "Illegal parameter",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_PARAMETER_ARRAY_VALUE",
        title: "Illegal parameter array value",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_PARAMETER_DATA_TYPE",
        title: "Illegal parameter data type",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_PARAMETER_EMPTY_VALUE",
        title: "Illegal empty parameter value",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_PARAMETER_LOCATION",
        title: "Illegal parameter location",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_PARAMETER_MULTIPART_NULL_VALUE",
        title: "Null in multi-part parameter value",
        alarm: false,
        block: false,
        disabled: true,
      },
      {
        name: "VIOL_PARAMETER_NAME_METACHAR",
        title: "Illegal meta character in parameter name",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_PARAMETER_NUMERIC_VALUE",
        title: "Illegal parameter numeric value",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_PARAMETER_REPEATED",
        title: "Illegal repeated parameter name",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_PARAMETER_STATIC_VALUE",
        title: "Illegal static parameter value",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_PARAMETER_VALUE_BASE64",
        title: "Illegal Base64 value",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_PARAMETER_VALUE_LENGTH",
        title: "Illegal parameter value length",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_PARAMETER_VALUE_METACHAR",
        title: "Illegal meta character in value",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_PARAMETER_VALUE_REGEXP",
        title: "Parameter value does not comply with regular expression",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_POST_DATA_LENGTH",
        title: "Illegal POST data length",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_QUERY_STRING_LENGTH",
        title: "Illegal query string length",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_RATING_THREAT",
        title: "Request is likely a threat",
        alarm: true,
        block: true,
        disabled: false,
      },
      {
        name: "VIOL_RATING_NEED_EXAMINATION",
        title: "Request needs further examination",
        alarm: false,
        block: false,
        disabled: true,
      },
      {
        name: "VIOL_REQUEST_LENGTH",
        title: "Illegal request length",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_REQUEST_MAX_LENGTH",
        title: "Request length exceeds defined buffer size",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_THREAT_CAMPAIGN",
        title: "Threat Campaign detected",
        alarm: true,
        block: true,
        disabled: false,
      },
      {
        name: "VIOL_URL",
        title: "Illegal URL",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_URL_CONTENT_TYPE",
        title: "Illegal request content type",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_URL_LENGTH",
        title: "Illegal URL length",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_URL_METACHAR",
        title: "Illegal meta character in URL",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_XML_FORMAT",
        title: "XML data does not comply with format settings",
        alarm: true,
        block: false,
        disabled: false,
      },
      {
        name: "VIOL_XML_MALFORMED",
        title: "Malformed XML data",
        alarm: true,
        block: true,
        disabled: false,
      },
    ];
  };

  static getAllServerTechnologies: () => string[] = () => {
    return [
      "ASP",
      "ASP.NET",
      "AngularJS",
      "Apache Struts",
      "Apache Tomcat",
      "Apache/NCSA HTTP Server",
      "BEA Systems WebLogic Server",
      "Backbone.js",
      "CGI",
      "Cisco",
      "Citrix",
      "CodeIgniter",
      "CouchDB",
      "Django",
      "Elasticsearch",
      "Ember.js",
      "Express.js",
      "Front Page Server Extensions (FPSE)",
      "Google Web Toolkit",
      "GraphQL",
      "Handlebars",
      "IBM DB2",
      "IIS",
      "JBoss",
      "Java Servlets/JSP",
      "JavaScript",
      "JavaServer Faces (JSF)",
      "Jenkins",
      "Jetty",
      "Joomla",
      "Laravel",
      "Lotus Domino",
      "Macromedia ColdFusion",
      "Macromedia JRun",
      "Microsoft SQL Server",
      "Microsoft Windows",
      "MongoDB",
      "MooTools",
      "Mustache",
      "MySQL",
      "Nginx",
      "Node.js",
      "Novell",
      "Oracle",
      "Oracle Application Server",
      "Oracle Identity Manager",
      "Outlook Web Access",
      "PHP",
      "PostgreSQL",
      "Prototype",
      "Proxy Servers",
      "Python",
      "React",
      "Redis",
      "RequireJS",
      "Ruby",
      "SQLite",
      "SSI (Server Side Includes)",
      "SharePoint",
      "Spring Boot",
      "Sybase/ASE",
      "TYPO3 CMS",
      "UIKit",
      "Underscore.js",
      "Unix/Linux",
      "Vue.js",
      "WebDAV",
      "WordPress",
      "XML",
      "ZURB Foundation",
      "Zend",
      "ef.js",
      "jQuery",
    ];
  };
}
