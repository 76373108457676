import React from "react";

export const PolicyConvertSidebarComponent: React.VoidFunctionComponent =
  () => {
    return (
      <React.Fragment>
        <div
          style={{
            paddingLeft: "17px",
            paddingRight: "17px",
            paddingTop: "0px",
          }}
        ></div>
      </React.Fragment>
    );
  };
