export const athenaViolations: string[] = [
  "VIOL_FILETYPE",
  "VIOL_METHOD",
  "VIOL_MANDATORY_HEADER",
  "VIOL_HTTP_RESPONSE_STATUS",
  "VIOL_REQUEST_MAX_LENGTH",
  "VIOL_FILE_UPLOAD",
  "VIOL_FILE_UPLOAD_IN_BODY",
  "VIOL_XML_MALFORMED",
  "VIOL_JSON_MALFORMED",
  "VIOL_ASM_COOKIE_MODIFIED",
  "VIOL_HTTP_PROTOCOL_MULTIPLE_HOST_HEADERS",
  "VIOL_HTTP_PROTOCOL_BAD_HOST_HEADER_VALUE",
  "VIOL_HTTP_PROTOCOL_UNPARSABLE_REQUEST_CONTENT",
  "VIOL_HTTP_PROTOCOL_NULL_IN_REQUEST",
  "VIOL_HTTP_PROTOCOL_BAD_HTTP_VERSION",
  "VIOL_HTTP_PROTOCOL_CRLF_CHARACTERS_BEFORE_REQUEST_START",
  "VIOL_HTTP_PROTOCOL_NO_HOST_HEADER_IN_HTTP_1_1_REQUEST",
  "VIOL_HTTP_PROTOCOL_BAD_MULTIPART_PARAMETERS_PARSING",
  "VIOL_HTTP_PROTOCOL_SEVERAL_CONTENT_LENGTH_HEADERS",
  "VIOL_HTTP_PROTOCOL_CONTENT_LENGTH_SHOULD_BE_A_POSITIVE_NUMBER",
  "VIOL_EVASION_DIRECTORY_TRAVERSALS",
];

export const supportedEvasions: { [key: string]: string } = {
  "Directory traversals": "VIOL_EVASION_DIRECTORY_TRAVERSALS",
};

export const supportedHttpProtocols: { [key: string]: string } = {
  "Multiple host headers": "VIOL_HTTP_PROTOCOL_MULTIPLE_HOST_HEADERS",
  "Header name with no header value":
    "VIOL_HTTP_PROTOCOL_BAD_HOST_HEADER_VALUE",
  "Null in request": "VIOL_HTTP_PROTOCOL_NULL_IN_REQUEST",
  "Retrieving data. Wait a few seconds and try to cut or copy again.":
    "VIOL_HTTP_PROTOCOL_BAD_MULTIPART_PARAMETERS_PARSING",
};

export const athenaAttackSignatures = [
  "ATTACK_TYPE_OTHER_APPLICATION_ATTACKS",
  "ATTACK_TYPE_DETECTION_EVASION",
  "ATTACK_TYPE_VULNERABILITY_SCAN",
  "ATTACK_TYPE_AUTHENTICATION_AUTHORIZATION_ATTACKS",
  "ATTACK_TYPE_BUFFER_OVERFLOW",
  "ATTACK_TYPE_PREDICTABLE_RESOURCE_LOCATION",
  "ATTACK_TYPE_INFORMATION_LEAKAGE",
  "ATTACK_TYPE_DIRECTORY_INDEXING",
  "ATTACK_TYPE_PATH_TRAVERSAL",
  "ATTACK_TYPE_XPATH_INJECTION",
  "ATTACK_TYPE_SERVER_SIDE_CODE_INJECTION",
  "ATTACK_TYPE_COMMAND_EXECUTION",
  "ATTACK_TYPE_SQL_INJECTION",
  "ATTACK_TYPE_CROSS_SITE_SCRIPTING",
  "ATTACK_TYPE_DENIAL_OF_SERVICE",
  "ATTACK_TYPE_HTTP_RESPONSE_SPLITTING",
  "ATTACK_TYPE_NON_BROWSER_CLIENT",
  "ATTACK_TYPE_TROJAN_BACKDOOR_SPYWARE",
  "ATTACK_TYPE_ABUSE_OF_FUNCTIONALITY",
  "ATTACK_TYPE_LDAP_INJECTION",
  "ATTACK_TYPE_HTTP_PARSER_ATTACK",
  "ATTACK_TYPE_SESSION_HIJACKING",
];

export const supportedAttackSignatures: { [key: string]: string } = {
  "Other Application Attacks Signatures":
    "ATTACK_TYPE_OTHER_APPLICATION_ATTACKS",
  "High Accuracy Detection Evasion Signatures": "ATTACK_TYPE_DETECTION_EVASION",
  "Vulnerability Scan Signatures": "ATTACK_TYPE_VULNERABILITY_SCAN",
  "Authentication/Authorization Attack Signatures":
    "ATTACK_TYPE_AUTHENTICATION_AUTHORIZATION_ATTACKS",
  "Buffer Overflow Signatures": "ATTACK_TYPE_BUFFER_OVERFLOW",
  "Predictable Resource Location Signatures":
    "ATTACK_TYPE_PREDICTABLE_RESOURCE_LOCATION",
  "Information Leakage Signatures": "ATTACK_TYPE_INFORMATION_LEAKAGE",
  "Directory Indexing Signatures": "ATTACK_TYPE_DIRECTORY_INDEXING",
  "Path Traversal Signatures": "ATTACK_TYPE_PATH_TRAVERSAL",
  "XPath Injection Signatures": "ATTACK_TYPE_XPATH_INJECTION",
  "Server Side Code Injection Signatures":
    "ATTACK_TYPE_SERVER_SIDE_CODE_INJECTION",
  "Command Execution Signatures": "ATTACK_TYPE_COMMAND_EXECUTION",
  "SQL Injection Signatures": "ATTACK_TYPE_SQL_INJECTION",
  "Cross Site Scripting Signatures": "ATTACK_TYPE_CROSS_SITE_SCRIPTING",
  "Denial of Service Signatures": "ATTACK_TYPE_DENIAL_OF_SERVICE",
  "HTTP Response Splitting Signatures": "ATTACK_TYPE_HTTP_RESPONSE_SPLITTING",
};
